@alert-border-success: #3C763D;
@alert-border-info: #5bc0de;
@alert-border-danger: #d9534f;
@alert-border-notice: #BDBD89;
@alert-border-warning: #f0ad4e;
@alert-border-default: #B4B4B4;

.alert-message {
  margin: 20px 0;
  padding: 20px;
  border-left: 3px solid #eee;
}

.alert-message h4 {
  margin-top: 0;
  margin-bottom: 5px;
}

.alert-message p:last-child {
  margin-bottom: 0;
}

.alert-message code {
  background-color: #fff;
  border-radius: 3px;
}

.alert-message-success {
  background-color: #F4FDF0;
  border-color: @alert-border-success;
}

.alert-message-success h4 {
  color: @alert-border-success;
}

.alert-message-danger {
  background-color: #fdf7f7;
  border-color: @alert-border-danger;
}

.alert-message-danger h4 {
  color: @alert-border-danger;
}

.alert-message-warning {
  background-color: #fcf8f2;
  border-color: @alert-border-warning;
}

.alert-message-warning h4 {
  color: @alert-border-warning;
}

.alert-message-info {
  background-color: #f4f8fa;
  border-color: @alert-border-info;
}

.alert-message-info h4 {
  color: @alert-border-info;
}

.alert-message-default {
  background-color: #EEE;
  border-color: @alert-border-default;
}

.alert-message-default h4 {
  color: #000;
}

.alert-message-notice {
  background-color: #FCFCDD;
  border-color: @alert-border-notice;
}

.alert-message-notice h4 {
  color: #444;
}

.alert-popup {
  margin-top: 0;
}

.label-narcotics {
  background-color: #c24a2a;
  color: #fff;
}
