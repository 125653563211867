@notification-border-color: #e7ecf1;
@notification-hover-color: #f5f5f5;
@notification-background-color: #ffffff;
@notification-mark-read-color: @alert-border-warning;
@notification-delete-color: #d9534f;
@notification-action-color: #65a0d0;

#notificationArea {
  transition: right .3s;
  position: fixed;
  z-index: 9999;
  top: 55px;
  bottom: 0;
  width: 340px;
  right: -340px;
  height: 100%;
  background: @notification-background-color;
  border: 1px solid @notification-border-color;
  font-size: 13px;

  #notification-list {
    background-color: @notification-background-color;
    overflow-y: auto;
    height: 86%;

    .alert-message {
      padding: 0;
    }

    &::-webkit-scrollbar {
      width: 7px;
      background-color: @notification-background-color;

      &-thumb {
        background-color: rgba(98, 98, 98, 0.4);
      }
    }

    a {
      color: @navbar-default-link-color;

      &:hover {
        text-decoration: none;
      }
    }

    .notification {
      margin: 0 5px 10px 5px;
      min-height: 110px;

      &:hover {
        background-color: @notification-hover-color;
      }

      .notification-info {
        width: 80%;
        float: left;
        padding: 10px 5px 10px 5px;
      }

      .group {
        width: 23px;
        color: #fff;
        display: flex;
        float: left;
        height: 110px;
        align-items: center;
        padding: 0 3px 0 0;

        &.group-warning {
          background-color: @alert-border-warning;
        }

        &.group-danger {
          background-color: @alert-border-danger;
        }

        &.group-info {
          background-color: @alert-border-info;
        }

        &.group-notice {
          background-color: @alert-border-notice;
        }

        &.group-default {
          background-color: @alert-border-default;
        }

        &.group-success {
          background-color: @alert-border-success;
        }
      }

      .actions {
        width: 12%;
        float: right;
        padding: 10px 0 10px 0;

        a {
          width: 50%;
          float: right;

          &.delete-notification:hover {
            color: @notification-delete-color;
          }

          &.mark-as-read:hover {
            color: @notification-mark-read-color;
          }
        }
      }

      .notification-read {
        color: lightgrey;

        &:hover {
          color: @navbar-default-link-color;
        }
      }
    }

    .type-header {
      padding: 10px;
      margin-bottom: 5px;
      font-size: 13px;
      background-color: #f5f5f5;
      color: #888;
    }
  }

  .notification-actions {
    padding: 10px;
    border: 1px solid @notification-border-color;

    .notification-action {
      font-weight: bold;
      color: @notification-action-color;

      &:hover {
        text-decoration: underline;
      }

      &.notification-element {
        float: right;
      }
    }
  }

  &.active {
    transition: right .3s;
    right: 0;
  }
}

.notification-element {
  > a {
    #counter {
      display: inline-block;
    }
    &:hover{
      #counter {
      }
    }
  }
}
