.form-inline {
  .radio {
    padding-right: 20px;
  }
}

.form-padding-top {
  margin-bottom: 0;

  .btn {
    width: 100%;
    margin-top: 40px;
  }
  .form-group {
    padding-top: 15px;
    margin-bottom: 15px;
    span, ul {
      margin-bottom: 0;
    }
    &.has-error {
      margin-bottom: 5px;
    }
  }

  tbody > tr > td {
    vertical-align: top;
  }
}

.select2-selection {
  &.select2-selection- {
    &-single, &-multiple {
      border: 1px solid #e5e8ed;
      border-radius: 0;
      height: 34px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }
    &-multiple {
      height: auto;
      &:focus {
        border: 1px solid #66afe9;
      }
    }
  }
}

.has-error {
  border-color: #a94442;
  .select2-dropdown, .select2-selection {
    border-color: #a94442;
  }
}

.product-error {
  color: #a94442;
}

.select2-container--default {
  .select2-results__option:not([id]) {
    min-height: 32px;
  }

  .select2-selection--single {
    .select2-selection__rendered {
      padding-left: 12px;
      padding-top: 3px;
    }

    .select2-selection__arrow {
      height: 30px;

      b {
        margin-left: 0;
        border-color: #555 transparent;
        border-width: 6px 3px 0 3px;
      }
    }
  }

  .select2-selection--multiple {
    .select2-selection__choice {
      border-radius: 0;
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: #218ae4;
  }

  &.select2-container--focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;

    .select2-selection--multiple {
      border: 2px solid #66afe9;
      border-radius: 2px;
    }
  }

  &.select2-container--open {
    .select2-dropdown {
      box-shadow: 0 3px 9px #ada8a8;
      border-top: 1px solid #66afe9;
      &--below {
        border-radius: 0;
      }

      .select2-results {
        .select2-results__option {
          min-height: 32px;
          height: auto;
        }
      }
    }

    .select2-selection--single {
      .select2-selection__arrow {
        b {
          border-color: #555 transparent;
          border-width: 0 3px 6px 3px;
        }
      }
    }

    .select2-results {
      .select2-results__option {
        &.select2-results__message {
          height: inherit;
        }
      }
    }
  }
}

.select2-selection--multiple:before {
  content: "";
  position: absolute;
  right: 7px;
  top: 42%;
  border-top: 5px solid #888;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

@-moz-document url-prefix() {
  #mediaUploadType_file {
    min-height: 50px;
  }
}

.labels {
  .col-sm-2 {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.label-order {
  .control-label {
    text-align: center;
  }
}

.disabled-radio {
  input[type="radio"] {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.6;
  }
}

.disable-select {
  pointer-events: none !important;
  color: #808080 !important;
}

.form-group {
  &.with-alert {
    margin-bottom: 0;

    .alert {
      margin-bottom: 15px;

      ul.existing-ro-reports {
        padding-left: 30px;
      }
    }
  }

  .inline-radio {
    margin-bottom: 7px;

    .radio {
      display: inline-block;
      margin-right: 20px;
    }
  }

  .form-control {
    &.planning-comment {
      resize: none;
      height: 500px !important;
    }
  }
}

.form-section {
  margin: 30px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #e7ecf1;
}

.tooltip {
  &-input {
    margin-left: 5px;
  }
}

.maintenance-form {
  textarea {
    min-height: 68px;
  }

  .comment-label {
    text-align: right;
  }
}

.search-bar {
  padding: 20px;
  margin-bottom: 40px;
  background-color: #fff;

  &.bordered {
    border: 1px solid #e7ecf1;
  }

  input {
    border: none;
    background-color: #f3f6f9;
    color: #a0a9b4;
    height: 55px;
    box-shadow: none;
  }

  button {
    height: 55px;
    width: 130px;
    margin-top: -2px;

    &.icon-only {
      width: 60px;
    }

    &.straight {
      border-radius: 0;
    }
  }
}

.page-ranking {
  .search-bar-placeholder {
    min-height: 97px;
  }

  .search-bar {
    margin-bottom: 20px;

    &.search-sticky {
      position: fixed;
      top: 55px;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: none;
    }
  }
}

#planning-form {
  .input-group {
    .input-group-addon {
      min-width: 37px;
    }
  }
}

span.select2 {
  width: 100% !important;
}

.text-editor {
  .CodeMirror, .CodeMirror-scroll {
    min-height: 150px;
  }
}

.registrationNo {
  padding-right: 30px;

  .input-group-addon {
    background-color: transparent;
    border: none;
    position: absolute;
    margin: 4px 10px 4px 4px;
    z-index: 99999;
    right: 0;
  }

  &.valid {
    input {
      border-color: #81c784;
    }
    .input-group-addon {
      color: #81c784;

      .form-control-feedback {
        color: #81c784;
      }
    }
  }

  &.inValid {
    input {
      border-color: #ef5350;
    }
    .input-group-addon {
      color: #ef5350;
      border-color: #ef5350;
    }
  }
}

#connection-status {
  margin-left: 10px;
}

.connection-status {
  margin-bottom: 20px;
}
