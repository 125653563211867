@table-row-even-dark: #fdf2f2;
@table-row-even-light: #fffaf9;
@fixed-table-background: #fff;

.table.table-data {
  tr > th:nth-child(even) {
    background: @table-row-even-dark;
  }

  tr:nth-child(even) > th:nth-child(even) {
    background: @table-row-even-light;
  }

  tr > td:nth-child(even) {
    background: @table-row-even-dark;
  }

  tr:nth-child(even) > td:nth-child(even) {
    background: @table-row-even-light;
  }

  tr > td {
    &.bg-warning {
      background: #f99a1b;
    }

    &.bg-success {
      background: #86cc69;
    }

    .badge {
      margin-left: 4px;
      vertical-align: text-top;
    }

    .text-muted {
      color: #aaa;
    }
  }

  tr > th {
    h1, h2, h3 {
      margin: 10px 0;
    }
  }
}

.package-table-wrapper {
  position: relative;

  .table-scroller {
    margin-left: 225px;
    overflow-y: auto;
    padding-bottom: 5px;

    .table-fixed-col {
      border-spacing: 0;
      border-collapse: collapse;
      border: 0;

      thead {
        .first-col {
          background: #ddd;
        }
      }

      th, td {
        white-space: nowrap;
      }

      .first-col {
        display: inline-block;
        padding: 5px;
        left: 0;
        position: absolute;
        top: auto;
        width: 225px;
      }
    }
  }
}

.table {
  .sort-link {
    margin-right: 10px;

    .fa {
      float: right;
      margin-top: 3px;
      color: #ccc;
    }
  }

  .vertical-top {
    vertical-align: top;
  }

  .vertical-middle {
    th {
      vertical-align: middle;
    }
  }

  tbody > tr > td {
    vertical-align: middle;
    .check-box {
      border: 1px solid black;
      width: 10px;
      height: 10px;
      float: left;
      margin: 3px;
    }
    .confirmed {
      float: left;
    }
  }

  .col-min {
    width: 1%;
    white-space: nowrap;

    .btn-link {
      color: @gray-light;
      padding: 4px;

      &:hover {
        .fa-times:hover {
          color: @brand-danger;
        }
        .fa-search:hover {
          color: @brand-info;
        }
      }
    }
  }
  tbody {
    .table-line-border-top {
      td {
        border-top: 2px solid #bfbfbf;
      }
    }
    .table-line-border-bottom {
      td {
        border-bottom: 2px solid #bfbfbf;
      }
    }
    .first {
      padding-top: 13px;
    }
  }

  &.package-table {
    tbody > tr > th {
      vertical-align: middle;
    }

    &.table-condensed {
      > tbody > tr > td.inner-table-col {
        padding: 0;
      }
    }
  }

  &.ro-reports-table {
    thead > tr > th:first-child {
      min-width: 80px;
    }
  }

  &-maintenance {
    .label {
      padding-top: .4em;
    }
  }

  .actions {
    &.btn-group {
      &.open {
        >.dropdown-menu {
          display: block;
        }
      }

      > .dropdown-menu {
        margin-top: 10px;

        > li {
          > a {
            padding: 8px 16px;
            color: #6f6f6f;
            text-decoration: none;
            display: block;
            clear: both;
            font-weight: 300;
            line-height: 18px;
            white-space: nowrap;
          }
        }

        li {
          &.divider {
            background: #f1f3f6;
          }

          .divider {
            height: 1px;
            margin: 9px 0;
            overflow: hidden;
            background-color: #e5e5e5;
          }
        }

        &:before  {
          position: absolute;
          top: -8px;
          left: 9px;
          right: auto;
          display: inline-block!important;
          border-right: 8px solid transparent;
          border-bottom: 8px solid #e0e0e0;
          border-left: 8px solid transparent;
          content: '';
        }

        &:after {
          position: absolute;
          top: -7px;
          left: 10px;
          right: auto;
          display: inline-block!important;
          border-right: 7px solid transparent;
          border-bottom: 7px solid #fff;
          border-left: 7px solid transparent;
          content: '';
        }
      }
    }

    .dropdown-menu {
      box-shadow: 5px 5px rgba(102,102,102,.1);
      left: 0;
      min-width: 175px;
      position: absolute;
      z-index: 1000;
      display: none;
      float: left;
      list-style: none;
      text-shadow: none;
      padding: 0;
      background-color: #fff;
      margin: 10px 0 0;
      border: 1px solid #eee;
      font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
      border-radius: 4px;
    }
  }

  &.pir-reports-table {
    .col-min {
      .btn {
        &.publish {
          :hover {
            color: @custom-success;
          }
        }
        &.published {
          cursor: default;
        }
      }
      .btn-link- {
        &success {
          color: @custom-success;
        }
      }
    }
  }
}

.comments-table {
  border: 1px solid black;
  min-height: 150px;
  padding: 5px;
  margin-bottom: 20px;
}

.btn.btn-link-info:hover {
  color: @brand-info;
}
.btn.btn-link-danger:hover {
  color: @brand-danger;
}
.btn.btn-link-warning:hover {
  color: darken(@brand-warning, 15%);
}
.btn.btn-link-primary:hover {
  color: @brand-primary;
}
.btn.btn-grey {
    color: @gray-light;
    padding: 4px;
}

.text-empty-data {
  font-size: 16px;
  background: transparent !important;
  text-align: center;
  color: #ccc;
  padding: 50px 0;
}

tr.text-empty-data {
  font-size: 16px;
  background: transparent !important;
}

.table caption {
  text-align: center;
  font-weight: bold;
  border-left: 1px solid @table-border-color;
  border-right: 1px solid @table-border-color;
  padding-bottom: 15px;

  h3 {
    margin-top: 10px;
    margin-bottom: 5px;
  }
}

.btn-muted-info {
  color: lighten(@text-muted, 15%);
  &:hover {
    color: @brand-info;
  }
}

.btn-muted-danger {
  color: lighten(@text-muted, 15%);
  &:hover {
    color: @brand-danger;
  }
}
.stocks-report-table {
  th {
    background-color: #ffffff;
  }
}

.table-no-margin {
  margin: 0;
}

.table-no-first-last-line {
  tr:first-child > td {
    border-top: 0;
  }
  tr:last-child > td {
    border-bottom: 0;
  }
}
.table-line-style-0 {
  background-color: #ffffff;
}
.table-line-style-1 {
  background-color: #e6e6e6;
}
.table-line-style-2 {
  background-color: #f3f3f3;
}

.table-wrapper {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;

  .table {
    width: auto;
  }
}

.package-type-label {
  margin: 1px;
}

.inner-table-col {
  background: #ffffff;
  padding: 0;

  .table {
    width: 100%;
    margin: 0;

    &.fixed {
      table-layout: fixed;
      word-wrap: break-word;

      td {
        white-space: normal;
      }
    }

    td {
      border: none;
      vertical-align: top;

      &:not(:last-child) {
        border-right: 1px solid @table-border-color;
      }
    }
  }
}

#package-material-table {
  .radio {
    display: inline-block;
    padding-right: 10px;
    padding-left: 10px;
  }

  .order {
    min-height: 150px;
  }
}

.floatThead-table {
  thead {
    background-color: @fixed-table-background;
  }
}

#raw-materials-table {
  margin-bottom: 0;

  tbody {
    tr {
      td {
        &.product-title {
          background-color: #e0e0e0;
        }
      }
    }
  }
}

#product-item-table {
  tbody {
    tr {
      height: 40px;

      td:nth-child(2) {
        min-width: 83px;
      }

      &:hover {
        cursor: pointer;
      }

      &.updated td {
        animation: change-color 1.5s ease-out;
      }

      @keyframes change-color {
        0%   { background-color: #dff0d8; }
        100% { background-color: #f5f5f5; }
      }
    }
  }
}

.table-bordered > tbody > tr > td.no-border {
  border-right: none;
  & + td {
    border-left: none;
  }
}

.table-layout {
  display: table;
  width: 100%;

  .table-cell {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
}

#materials-body {
  .empty-result {
    min-height: 80px;
    font-size: 16px;
    margin-top: 50px;
  }

  .flash-bag {
    &.alert {
      margin: 0;
    }
  }
}

table {
  .alert {
    margin-bottom: 0;
    padding: 5px;
  }

  .filters {
    input, select {
      padding-left: 2px;
      padding-right: 0;
    }

    .width-lg {
      width: 100px;
    }

    .width-md {
      width: 70px;
    }

    .width-sm {
      width: 60px;
    }

    .width-xs {
      width: 50px;
    };
  }
}

.table .sort-link .fa {
  float: none;
}

