#navbar-top {
  #gradient > .vertical(#fff, #efefef);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
  border: 0;

  .navbar-brand {
    padding-top: 10px;
    padding-bottom: 0;
    color: #777;
    line-height: 30px;
    font-size: 20px;

    > img {
      display: inline;
      float: left;
      margin-right: 8px;
    }
  }

  .navbar-nav {
    > .active > a, > .active > a:hover, > .active > a:focus {
      border-bottom: 5px solid @navbar-default-link-active-color;
      margin-bottom: -5px;
    }

    > li > .dropdown-menu {
      margin-top: 5px;
      border-top: 0;

      a, span {
        color: #777;
      }

      > .active {
        a {
          background: @navbar-default-link-dropdown-color;
          color: #fff;
        }

        > .dropdown-menu {
          > li > a {
            background: #fff;
            color: #777;
          }

          > .active a {
            color: @navbar-default-link-dropdown-color;
          }
        }
      }

      > .dropdown-submenu {
        &.active  {
          background: #c24a2a;
          color: #fff;

          span {
            color: #fff;
          }
        }
      }
    }
  }
}

.dropdown-menu {
  > li > span {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    white-space: nowrap;
  }
}

.dropdown-submenu {
  position: relative;
  cursor: default;

  &:before {
    content: "\f0da";
    display: block;
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -8px;
  }

  .pull-left {
    float: none;

    > .dropdown-menu {
      left: -100%;
      margin-left: 10px;
      -webkit-border-radius: 6px 0 6px 6px;
      -moz-border-radius: 6px 0 6px 6px;
      border-radius: 6px 0 6px 6px;
    }
  }

  > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 0;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 0;
    border-left: none;
    -webkit-box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.175);
    -moz-box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.175);;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.175);
  }

  > a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-right-width: 0;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
  }

  &:hover {
    > .dropdown-menu {
      display: block;
    }

    > a:after {
      border-left-color: #fff;
    }
  }
}
