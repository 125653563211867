@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i&subset=latin-ext');
@import '../vendor/bootstrap/less/bootstrap';
@import '../vendor/fontawesome/less/font-awesome';
@import '../vendor/eonasdan-bootstrap-datetimepicker/src/less/_bootstrap-datetimepicker';
@import (inline) '../vendor/simplemde/dist/simplemde.min.css';
@import "app/navbar";
@import 'app/product';
@import 'app/panels';
@import 'app/tables';
@import 'app/forms';
@import 'app/modals';
@import 'app/theme';
@import 'app/fluid';
@import 'app/dashboard';
@import 'app/reports';
@import 'app/print';
@import 'app/alerts';
@import 'app/portlets';
@import 'app/buttons';
@import 'app/actiofarma';
@import 'app/notifications';

@fa-font-path: '/build/fonts';
