.portlet {
  margin-top: 0;
  margin-bottom: 25px;
  padding: 0;
  border-radius: 4px;

  &.bordered {
    border-left: 2px solid #e6e9ec !important;
  }

  & > .portlet-title {
    margin-bottom: 10px;

    &:after {
      clear: both;
      content: " ";
      display: table;
    }

    &:before {
      content: " ";
      display: table;
    }

    & > .caption {
      float: left;
      display: inline-block;
      font-size: 18px;
      line-height: 18px;
      padding: 10px 0;

      & > i {
        float: left;
        margin-right: 5px;
      }

      & > .caption-helper {
        padding: 0;
        margin: 0;
        line-height: 13px;
        color: #9eacb4;
        font-size: 13px;
        font-weight: 400;
      }
    }

    & > .actions {
      float: right;
      display: inline-block;
      padding: 6px 0;

      & > .btn {
        &.btn-default {
          padding: 3px 9px;
        }

        &.btn-sm {
          padding: 4px 10px;
          font-size: 13px;
          line-height: 1.5;

          > i {
            font-size: 13px;
          }
        }
      }

      .btn-icon-only {
        &.btn-default {
          padding: 4px 6px 2px;

          & > i {
            font-size: 14px;
          }
        }
      }
    }
  }

  &.straight {
    border-radius: 0;
  }

  &.light {
    padding: 12px 20px 15px;
    background-color: #fff;

    & > .portlet-title {
      padding: 0;
      min-height: 48px;

      & > .caption {
        color: #666;
        padding: 10px 0;

        & > i {
          font-weight: 300;
          margin-top: 3px;
        }

        &.caption-md {
          & > i {
            font-size: 14px;
          }

          & > .caption-subject {
            font-size: 15px;
          }
        }
      }

      & > .actions {
        padding: 6px 0 14px;

        .btn-icon-only {
          height: 27px;
          width: 27px;
        }

        .btn-default {
          color: #666;
        }
      }
    }

    &.bordered {
      border: 1px solid #e7ecf1 !important;

      & > .portlet-title {
        border-bottom: 1px solid #eef1f5;
      }
    }

    .portlet-body {
      padding-top: 8px;
    }
  }

  & > .portlet-body {
    clear: both;
  }
}
