@font-family-base: 'Ubuntu', sans-serif;

@navbar-default-link-color: #888;
@navbar-default-link-active-color: #f04e23;
@navbar-default-link-hover-color: #f04e23;
@navbar-default-link-dropdown-color: #c24a2a;
@navbar-default-link-active-bg: transparent;

@text-color: #495b6a;
@headings-color: darken(@text-color, 10%);
@link-color: #027ce9;

@panel-default-border: #e5e8ed;
@panel-border-radius: 0;

@table-border-color: #e5e8ed;
@input-border: #e5e8ed;
@input-border-radius: 0;
@input-border-radius-large: 0;
@input-border-radius-small: 0;
@btn-default-border: @input-border;
@btn-simple-bg: #dddddd;

@custom-success: #5cb85c;

.open-sans {
  font-family: 'Open Sans', sans-serif;

  .italic {
    font-style: italic;
  }
}

#navbar-top {
  #gradient > .vertical(#fff, #efefef);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
  border: 0;

  .navbar-brand {
    padding-top: 10px;
    padding-bottom: 0;
    color: #777;
    line-height: 30px;
    font-size: 20px;

    > img {
      display: inline;
      margin-right: 8px;
    }
  }

  .navbar-nav {
    > .active > a, > .active > a:hover, > .active > a:focus {
      border-bottom: 5px solid @navbar-default-link-active-color;
      margin-bottom: -5px;
    }

    > li > .dropdown-menu {
      margin-top: 5px;
      border-top: 0;

      a {
        color: #777;
      }

      > .active a {
        background: #c24a2a;
        color: #fff;
      }
    }
  }
}

.form-control-feedback.fa {
  width: 50px;
  right: 7px;
  top: 9px;
}

.control-label.required:after {
  content: '*';
  color: #c4817f;
  margin-left:1px;
}

.input-group-addon {
  .form-control-feedback {
    position: relative;

    &.fa {
      width: auto;
      height: auto;
      right: auto;
      top: auto;
    }
  }
}

h2 .label.pull-right {
  margin-top: 13px;
  font-size: @font-size-base;
}


html {
  position: relative;
  min-height: 100%;
}

body {
  // Margin bottom by footer height
  margin-bottom: 60px;
  padding-top: @navbar-height;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  // Set the fixed height of the footer here
  height: 60px;
  background-color: #f5f5f5;
  border-top: 2px solid #ddd;
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  padding-top: 18px;
}

#container-body {
  > .panel:first-child, > .alert:first-child {
    margin-top: 40px;
  }
}

#container-body {
  padding-bottom: 30px;
}

.nav-border {
  height: 5px;
  #gradient > .horizontal-three-colors(#f04e23, #faa61a, 50%, #faa61a);
}

.form-group.has-warning.bg-warning {
  padding: 20px 0;
  border: 1px solid @alert-warning-border;
}

.CodeMirror-fullscreen, .editor-toolbar.fullscreen, .editor-preview-active-side {
  z-index: 1040;
}

.editor-toolbar, .CodeMirror {
  border-color: @input-border;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border-radius: 0;
}

.popover .title {
  font-weight: bold;
  margin: 0 -14px 7px;
  padding: 7px 14px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;

  &:first-child {
    border-top: 0;
    padding-top: 0;
  }
}

.section-header h4 {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
}

.single-line {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table-fixed {
  table-layout: fixed;
  tr > td {
    word-wrap: break-word;
  }
}
.text-nowrap {
  white-space: nowrap;
}

.modal-body .modal-footer {
  margin: 30px -15px -15px;
}

.modal-content {
  .container {
    width: 750px;
  }
  .footer, .navbar-fixed-top {
    position: static;
  }
}

.form-collection {
  margin-bottom: 15px;
}
.sticky-search {
  position: fixed;
  top: 25%;
  left: 2%;
  background: white;
  padding: 10px;
  border: 1px solid #eeeeee;
  z-index: 1;

  .search {
    margin-bottom: 10px;
  }
}
.signature-img {
  height: 90px;
}
.light-red-background {
  background-color: #f47758;
}
.red-font {
  color: #f44336;
}

.green-font {
  color: #4caf50;
}

.yellow-font {
  color: #f1c40f;
}

.orange-font {
  color: #ffcc80;
  &:hover {
    color: #ffb74d;
  }
}

.nav-reports {
  padding-top: 15px;
  text-align: center;

  .disable-link {
    pointer-events: none;
    color: #727272;
  }

  .item-count {
    width: 50px;
    padding: 7px;
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
    display: inline-block;
    text-align: center;
    border-radius: 5px;
  }
}

.deregistered-loop (@index) when (@index > 0) {
  .dr@{index} > tbody > tr > td:nth-child(@{index}) {
    background-color: #ffebeb;

    > * {
      background-color: #ffebeb;
    }
  }

  .deregistered-loop (@index - 1);
}

.deregistered-loop(10);

.no-padding {
  padding: 0 !important;
}
.no-padding-sides {
  padding-left: 0;
  padding-right: 0;
}
.inline-block {
  display: inline-block;
}
.hide-desktop {
  display: none;
}
.order-sold,
.order-balance {
  float: left;
  width: 10%;
}
.back-to-search {
  left: 0;
  position: absolute;
}
.cd-panel {
  visibility: hidden;
  transition: visibility 0s 0.6s;
}
.cd-panel.is-visible {
  visibility: visible;
  transition: visibility 0s 0s;
}
.cd-panel-header {
  position: fixed;
  top: -60px;
  width: 100%;
  height: 50px;
  transition: top 0.3s 0s;
}
.is-visible .cd-panel-header {
  top: 0;
  transition: top 0.3s 0.3s;
}
.cd-panel-container {
  position: fixed;
  width: 250px;
  height: 100%;
  top: 50px;
  left: 0;
  z-index: 2;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  transform: translate3d(100%, 0, 0);
  .sticky-search {
    position: fixed;
    top: 18%;
    left: 0;
  }
}
.is-visible .cd-panel-container {
  transform: translate3d(0, 0, 0);
  transition-delay: 0s;
}

a.cd-btn {
  position: fixed;
  left: 0;
  top: 25%;
  z-index: 0;
  -webkit-border-radius: 5px;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius: 5px;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-bottomleft: 0;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.spinner {
  display: inline-block;
  opacity: 0;
  max-width: 0;

  -webkit-transition: opacity 0.25s, max-width 0.45s;
  -moz-transition: opacity 0.25s, max-width 0.45s;
  -o-transition: opacity 0.25s, max-width 0.45s;
  transition: opacity 0.25s, max-width 0.45s;
}
.has-spinner.active {
  cursor: progress;
}
.has-spinner.active .spinner {
  opacity: 1;
  max-width: 50px;
}
.checkbox {
  &.role-check {
    width: 15px;
    height: 15px;
    text-align: center;
    float: none;
    display: inline;
  }
}

.pagination span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}
.pagination span a {
  cursor: pointer;
}

.pagination {
  .current {
    color: #fff;
    background-color: #337ab7;
    border-color: #204d74;
  }
}

.pagination span:first-of-type {
  -webkit-border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination span:last-of-type {
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.table-separator {
  background: #d3d3d3;
  font-weight: bold;
  font-style: italic;
}

.add-paper-size {
  position: absolute;
  right: -100px;
  margin-top: 2px;
}

.table-fixed-head .table-fixed-head-thead {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: #fafafa;
  color: #222;
}

.title-middle {
  vertical-align: middle;
}

.export-product {
  &.row {
    overflow-x: auto;
    white-space: nowrap;

    >.col-md-6 {
      white-space: normal;
      vertical-align: top;
      display: inline-block;
      float: none;

      textarea {
        height: 80px !important;
      }
    }
  }

  .add-button {
    &.fixed-position {
      position: fixed;
      right: 80px;
      top: 60px;
      z-index: 999;
    }
  }
}

.mt40 {
  margin-top: 40px;
}

.export-save {
  position: fixed;
  right: 10px;
  top: 60px;
  z-index: 999;
}

.buttons-row {
  height: 40px;
  left: 0;
  position: fixed;
  top: 55px;
  background: white;
  display: block;
  width: 100%;
  z-index: 998;
}

.toggle-product-view {
  font-size: 12px;
}

.export-product {
  label {
    white-space: pre-wrap;
  }
}

.export-pack-size {
  .second-row {
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .form-group > div {
    &:first-child {
      padding-left: 0;
    }

    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (max-width: 991px) {
  .navbar-header {
    float: none;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-nav {
    float: none !important;
    margin: 7.5px -15px;
  }

  .navbar-nav>li {
    float: none;
  }

  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .navbar-text {
    float: none;
    margin: 15px 0;
  }

  .navbar-collapse.collapse.in {
    display: block !important;
  }

  .collapsing {
    overflow: hidden !important;
  }
}

.bad-value {
  color :@navbar-default-link-active-color;
  font-weight: bold;
}

.dark-gray {
  color: #6B6B6B;
}

.position-absolute {
  position: absolute;
}

.actions {
  &.planning {
    .dropdown-menu {
      min-width: 50px;
      a {
        padding-right: 6px !important;
      }
    }
  }
}

.col-sticky {
  position: sticky;
  top: 90px;
}

.available-weeks {
  li {
    margin-bottom: 5px;
  }

  .form-group {
    margin-bottom: 5px;
  }

  .pagination {
    margin: 0;

    > li {
      &:first-child {
        > a, span {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }
      &:last-child {
        > a, span {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }
  }
}

.available-weeks-container {
  .pagination {
    margin-left: 20px;
    margin-bottom: 5px;
  }

  .goto-date {
    display: inline-block;
    margin-left: 20px;
    vertical-align: top;

    .control-label {
      padding-right: 10px;
    }

    > select, input {
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      background-color: #fff;
      border: 1px solid #dddddd;
    }

    #goto-year {
      line-height: 1.42857143;
      color: #555555;
      background-image: none;
      border-radius: 0;
    }

    #goto-week {
      width: 65px;
      background-color: #fff;
    }

    .goto-btn {
      vertical-align: initial;
    }
  }
}

.marked-planning {
  color: #bfbfbf;
}

.planning-short-expiry {
  background-color: @alert-warning-bg;
  &:hover {
    background-color: #faf3d1 !important;
  }
}

.planning-expired {
  background-color: @alert-danger-bg;
  &:hover {
    background-color: @alert-danger-border !important;
  }
}

#scroller {
  text-decoration: none;
  font-size: 12px;
  padding: 7px 15px 7px 15px;
  &:hover {
    background-color: @table-border-color;
    border-radius: 100px;
  }

  i {
    display: block;
    margin: 0;
  }
}

span {
  &.link {
    color: @link-color;
    text-decoration: none;

    &:hover,
    &:focus {
      color: @link-hover-color;
      text-decoration: @link-hover-decoration;
      cursor: pointer;
    }
  }
}

.btn {
  &.btn-simple {
    .button-variant(@btn-default-color; @btn-simple-bg; transparent);
  }
}

.underline {
  text-decoration: underline;
}

.absolute {
  position: absolute;
}

.modal-delete > .modal-content > .modal-body > .row {
  &.row-no-top-margin {
    margin-top: 0;
  }
}

.uppercase {
  text-transform: uppercase !important;
}
