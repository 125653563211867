.modal-delete {
  > .modal-content {
    > .modal-header {
      > .modal-title {
        text-transform: uppercase;
      }
    }

    > .modal-body {
      > .clearfix {
        margin-top: 20px;

        button {
          float: right;

          &:first-child {
            float: left;
          }
        }
      }
    }
  }
}

.pm-summary-modal {
  width: 500px;
  .modal-title {
    > h4 {
      margin-top: 0;
    }

    > h5 {
      margin-bottom: 0;
    }
  }

  .modal-body {
    table {
      tr:first-child {
        > td {
          border-top: none;
        }
      }
    }

    > .row {
      > h3 {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
}

.pm-summary-modal-lg {
  &:extend(.pm-summary-modal all);
  width: 1000px;
}

.modal {
  .modal-dialog {
    &.modal-xl {
      @media (min-width: 992px) {
        width: 80%;
      }
    }

    .modal-content {
      h3.form-section {
        font-weight: 300;
        font-size: 18px;
      }
    }
  }
}
