#dashboard, #po-orders {

  // ---- PORTLETS ----

  .portlet {
    margin-top: 0;
    margin-bottom: 25px;
    padding: 0;
    border-radius: 4px;

    &.bordered {
      border-left: 2px solid #e6e9ec !important;
    }

    & > .portlet-title {
      margin-bottom: 10px;

      &:after {
        clear: both;
        content: " ";
        display: table;
      }

      &:before {
        content: " ";
        display: table;
      }

      & > .caption {
        float: left;
        display: inline-block;
        font-size: 18px;
        line-height: 18px;
        padding: 10px 0;

        & > i {
          float: left;
          margin-right: 5px;
        }

        & > .caption-helper {
          padding: 0;
          margin: 0;
          line-height: 13px;
          color: #9eacb4;
          font-size: 13px;
          font-weight: 400;
        }
      }
    }

    &.light {
      padding: 12px 20px 15px;
      background-color: #fff;

      & > .portlet-title {
        padding: 0;
        min-height: 48px;

        & > .caption {
          color: #666;
          padding: 10px 0;

          & > i {
            font-weight: 300;
            margin-top: 3px;
          }

          &.caption-md {
            & > i {
              font-size: 14px;
            }

            & > .caption-subject {
              font-size: 15px;
            }
          }
        }
      }

      &.bordered {
        border: 1px solid #e7ecf1 !important;

        & > .portlet-title {
          border-bottom: 1px solid #eef1f5;
        }
      }

      .portlet-body {
        padding-top: 8px;
      }
    }

    & > .portlet-body {
      clear: both;
    }
  }

  // ---- TABLES ----

  .table {
    &.table-light {
      border: 0 !important;

      & > thead {
        & > tr {
          &.uppercase {
            text-transform: uppercase;
          }

          & > th {
            font-weight: 600;
            font-size: 13px;
            color: #93a2a9;
            border: 0;
            border-bottom: 1px solid #f2f5f8;
            font-family: "Open Sans", sans-serif;
          }
        }
      }

      & > tbody {
        &.empty-body {
          & > tr:hover {
            background-color: inherit;
          }
        }

        & > tr {
          &:last-child > td {
            border: 0;

            &.dataTables_empty {
              padding-top: 100px;
              opacity: 0.3;
              font-size: 18px;

            }
          }

          & > td {
            border: 0;
            border-bottom: 1px solid #f2f5f8;
            color: #8896a0;
            vertical-align: middle;

            &:first-child {
              white-space: nowrap;
            }

            .btn {
              &.green {
                span {
                  padding-left: 3px;
                }
              }
            }
          }
        }
      }
    }

    [data-toggle="tooltip"] {
      cursor: help;
    }

    .btn {
      margin-top: 0;
      margin-left: 0;
      margin-right: 5px;
    }
  }

  .table-scrollable {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border: 1px solid #e7ecf1;
    margin: 10px 0 !important;

    &.table-scrollable-borderless {
      border: 0;
    }

    & > .table {
      width: 100% !important;
      margin: 0 !important;
      background-color: #fff;
    }
  }

  // ---- TASK LIST ----

  .tasks-widget {
    .scroller {
      height: 312px;
      overflow: hidden;
      width: auto;
    }

    .task-list {
      list-style: none;
      padding: 0;
      margin: 0;

      &.with-buttons {
        li {
          padding-right: 70px;
        }
      }

      &.with-icons {
        li {
          padding-left: 43px;

          .icon {
            position: absolute;
            left: 10px;
            top: 50%;
            margin-top: -11px;
          }
        }
      }

      li {
        position: relative;
        padding: 10px;
        border-bottom: 1px solid #f4f6f9;

        .task-config-btn {
          margin-top: -1px;
        }

        .task-title {
          color: #838fa1;
          margin-right: 10px;

          .task-config {
            display: none;
            position: absolute;
            top: 7px;
            right: 10px
          }

          .task-title-sp {
            margin-right: 5px;
          }

          .btn {
            position: absolute;
            right: 15px;
            top: 50%;
            margin-top: -15px;
            padding: 4px 7px;
          }
        }

        &:hover {
          background: #f4f6f9;

          .task-title {
            .task-config {
              display: block;
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
  }

  // ---- BUTTONS ----

  .btn {
    &.btn-outline {
      &.green {
        border-color: #32c5d2;
        color: #32c5d2;
        background: 0 0;

        &:hover {
          border-color: #32c5d2;
          color: #FFF;
          background-color: #32c5d2;
        }
      }
    }
  }

  .btn-group {
    & > .dropdown-menu {
      margin-top: 10px;
    }
  }

  // ---- SCROLLBAR ---

  .scrollbar {
    &::-webkit-scrollbar {
      width: 7px;
      background-color: #ffffff;

      &-thumb {
        background-color: rgba(98, 98, 98, 0.4);
      }
    }
  }

  .slimScrollBar {
    border-radius: 0 !important;
  }

  // ---- OTHER ----

  .dataTables_scroll {
    .table > tbody > tr > td {
      padding: 7px;
    }
  }

  .primary-link {
    color: #65a0d0;
    font-weight: 600;
  }

  .font-dark {
    color: #2f353b;
  }

  .bold {
    font-weight: 700;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .yellow {
    color: #f1c40f;
  }

  .red {
    color: #ff0000;
  }

  a, button, code, div, img, input, label, li, p, pre, select, span, svg, table, td, textarea, th, ul {
    border-radius: 0 !important;
  }

  .label {
    &.icon {
      margin-right: 10px;
      padding: 5px 6px 6px 6px;
    }
    &.label-sm {
      padding: 4px 6px;
    }
  }

  .icon {
    &-tray {
      vertical-align: -8%;
    }
  }
}
