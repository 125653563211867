.btn {
  &.recalculate {
    margin-top: 10px;
  }

  &:not(.btn-sm) {
    &:not(.btn-lg) {
      line-height: 1.44;
    }
  }

  &.form-clear {
    margin-top: 25px;
  }

  &.disabled {
    pointer-events: none;
  }
}

.btn-icon-only {
  text-align: center;

  & > i {
    text-align: center;
    margin-top: 2px;
  }
}

.btn-square {
  border-radius: 0;
}

.bold {
  font-weight: 700;

  &.btn {
    outline: 0 !important;
  }
}