.report-wrapper {
  position: relative;

  .report-header {
    position: fixed;
    z-index: 1029;
    right: 0;
    left: 0;
    background-color: #fff;
    margin-top: 5px;

    .page-title {
      position: relative;
      margin-bottom: 30px;

      h1.row {
        margin-bottom: 20px;
      }
    }

    .page-title-action {
      position: absolute;
      right: 10px;
      top: 20px;
      text-align: right
    }

    .maintenance-filters {
      position: relative;
      padding-right: 150px;

      .btn {
        position: absolute;
        right: 10px;
        top: 25px;
        width: 88px;
      }
    }
  }

  hr {
    &.narrow-bottom {
      margin-bottom: 10px;
    }
    &.narrow-top-only {
      margin-top: 5px;
      margin-bottom: 0;
    }
  }

  .body-maintenance {
    margin-top: 310px;
  }

  .body-bov {
    margin-top: 250px;
  }
}

.pir-report {
  .text-success {
    color: @custom-success;
  }
}

.form-footer {
  .footer-buttons {
    padding-left: 60px;
  }
}

.po-priority-color {
  background-color: #e3f7c3;
}
