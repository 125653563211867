@navbar-actiofarma-link-active-color: #196f80;

@button-actiofarma-color: #1D8295;
@button-actiofarma-border-color: #196f80;

@button-actiofarma-activated-color: #196f80;
@button-actiofarma-activated-border-color: #196f80;

@notification-actiofarma-border-color: #26a6c0;

#actiofarma-body {
  .nav-border {
    #gradient > .horizontal-three-colors(#196f80, #2294aa, 50%, #6acee2);
  }

  #navbar-top {
    .navbar-nav {
      > .active > a, > .active > a:hover, > .active > a:focus {
        border-bottom: 5px solid @navbar-actiofarma-link-active-color;
      }

      > li > .dropdown-menu {
        .active > a {
          background: @navbar-actiofarma-link-active-color
        }

        .dropdown-submenu {
          &.active {
            background: @navbar-actiofarma-link-active-color;
            .active a {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .navbar-default .navbar-nav {
    > .active > a, > .active > a:active, > .active > a:hover, > .active > a:focus {
      color: @navbar-actiofarma-link-active-color;
    }

    > .open > a, > .open > a:active, > .open > a:hover, > .open > a:focus {
      color: @navbar-actiofarma-link-active-color;
    }

    > li > a:hover, > li > a:active, > li > a:focus {
      color: @navbar-actiofarma-link-active-color;
    }
  }

  .nav-pills {
    li {
      a {
        &:hover {
          border-bottom: 2px solid @navbar-actiofarma-link-active-color;
        }
      }

      &.active > a {
        color: @navbar-actiofarma-link-active-color;
        border-bottom: 2px solid @navbar-actiofarma-link-active-color;
      }

      &.active:hover a {
        color: @navbar-actiofarma-link-active-color;
        border-bottom: 2px solid @navbar-actiofarma-link-active-color;
      }
    }
  }

  h2.with-nav {
    a.btn.btn-link {
      &.active, &:hover {
        color: @navbar-actiofarma-link-active-color;
        border-bottom: 2px solid @navbar-actiofarma-link-active-color;
      }
    }
  }

  .btn {
    &.btn-warning {
      background-color: @button-actiofarma-color;
      border-color: @button-actiofarma-border-color;
      &.btn-warning:hover, &.btn-warning:active, &.btn-warning:focus {
        background-color: @button-actiofarma-activated-color;
        border-color: @button-actiofarma-activated-border-color;
      }
    }

    &.btn-link-warning:hover {
      color: @button-actiofarma-color;
    }
  }

  .lakameda-logo {
    margin-top: 20px;
  }

  #notificationArea {
    #notification-list {
      .notification {
        .actions {
          .mark-as-read:hover {
            color: @notification-actiofarma-border-color;
          }
        }
      }
    }
  }
}
