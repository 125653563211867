.nav-buttons.btn-group {
  bottom: 60px;
  .dropdown {
    float: left !important;
  }
  a.btn.btn-link {
    color: #666;
    padding: 13px 20px 8px;

    &.active {
      color: @navbar-default-link-hover-color;
      border-bottom: 2px solid @navbar-default-link-hover-color;
      margin-bottom: -1px;
    }

    &:hover {
      text-decoration: none;
      border-bottom: 2px solid @navbar-default-link-hover-color;
      margin-bottom: -1px;
    }
  }
}

.nav-pills {
  margin-top: -10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;

  > li {
    > a {
      border-radius: 0;
      color: #666;

      &:hover {
        background: transparent;
        border-bottom: 2px solid @navbar-default-link-hover-color;
        margin-bottom: -2px;
      }
    }

    &.active > a {
      background: #eee;
      color: @navbar-default-link-hover-color;
      border-bottom: 2px solid @navbar-default-link-hover-color;
      margin-bottom: -2px;
    }

    &.active:hover a {
      background: #eee;
      color: @navbar-default-link-hover-color;
      border-bottom: 2px solid @navbar-default-link-hover-color;
    }
  }
}

#product-form {
  ul.nav-tabs {
    margin-bottom: 15px;
  }
}

.form-heading {
  border-bottom: 1px solid darken(@table-border-color, 5%);
  #gradient > .vertical(transparent, #f2f2f2);
  padding: 15px;
  margin-bottom: 15px;
}

.form-footer {
  border-top: 1px solid darken(@table-border-color, 5%);
  #gradient > .vertical(#f2f2f2, #f9f9f9);
  padding: 15px 5px 15px 5px;
  margin-top: 15px;
}

.form-header ~ .table {
  margin: 0;
}

.table ~ .form-footer {
  margin-top: -21px;
}

.form-heading ~ .table {
  margin-top: -16px;
}

.active-cogs {
  margin: 0;
}

.export_sizes {
  .collection-form-item {
    &:first-child {
      .collection-form-remove-container {
        .more-options {
          margin-top: 39px;
        }
        .input-group-btn {
          > .btn {
            margin-top: 33px;
          }
        }
      }
    }

    .collection-form-remove-container {
      .more-options {
        margin-top: 7px;
      }
      .input-group-btn {
        > .btn {
          margin-top: 2px;
        }
      }
    }

    .second-row {
      > div {
        &:first-child {
          padding-right: 2px;
        }
        &:last-child {
          padding-left: 8px;
        }
      }

      .input-group {
        width: 100%;
      }

      .registration_numbers {
        .btn-danger {
          margin-top: 0 !important;
        }
      }
    }
  }
}

.collection, .materials-collection {
  .col-md-offset-3.col-md-9 {
    margin-left: 0;
    padding-left: 0;
    margin-top: 6px;
  }

  .collection-form-item {
    .help-block {
      color: #a94442;
    }

    .input-group-btn {
      .btn {
        float: right;
      }
    }

    &.labels-horizontal {
      .control-label {
        width: 100%;
        text-align: left;
        margin-bottom: 5px;
        padding-left: 3px;
      }
    }
  }

  .collection-form-remove-container {
    .control-label {
      width: 100%;
      text-align: center;
      margin-bottom: 5px;
    }

    .input-group-btn {
      &:last-child > .btn {
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
      }

      > .btn {
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
        margin-top: 33px;
      }
    }
  }

  .material-measurement {
    .measurement-value {
      padding-top: 4px;
    }
  }

  .material-visible {
    .checkbox {
      margin-top: 30px;
    }
  }
}
