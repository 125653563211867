@panel-heading-padding: 10px 15px;

.panel {
  box-shadow: 0 0 15px #ddd;

  .panel-heading {
    .panel-title {
      line-height: 31px;

      i.fa {
        margin-right: 10px;
      }
    }
    .panel-buttons {
      float: right;

      > .btn {
        margin-left: 5px;
      }
    }

    .toolbar {
      float: right;
      margin-left: @padding-small-horizontal;

      .btn {
        .btn-sm();
      }

      > .form-control, .btn, .btn-group  {
        display: inline-block;
      }
    }
  }

  .panel-body {
    &.ro-report {
      padding: 30px;
    }
  }

  &.panel-default {
    position: relative;
    .panel-heading {
      #gradient > .vertical(#f2f2f2, #f9f9f9);

      .panel-title {
        font-weight: bold;
        color: #294553;
      }
    }
  }

  &.panel- {
    &collapse {
      > .panel-heading {
        a {
          display: inline-block;
          position: relative;
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }
          &:focus {
            text-decoration: none;
          }
        }

        .panel-title a {
          display: block;
          padding: 10px 15px;
          margin: -10px -15px;

          &:after {
            font-family: "FontAwesome";
            content: "\f0d7";
            position: absolute;
            right: 20px;
            top: 50%;
            margin-top: -15px;
          }

          &[aria-expanded="true"] {
            &:after {
              font-family: "FontAwesome";
              content: "\f0d8";
            }
          }

          &.collapsed:after {
            font-family: "FontAwesome";
            content: "\f0d7";
          }
        }
      }

      #filters {
        .select2-container {
          width: 100%;
        }
      }
    }

    &product-search {
      .select2 {
        width: 100% !important;
      }
    }
  }

  .panel-footer {
    .pagination {
      float: right;
      margin: 0;
    }
    .pagination-summary {
      // btn height + padding + border
      line-height: @line-height-computed + @padding-base-vertical*2 + 2;
      margin: 0;
    }
  }
}
