@media print {
  body {
    padding: 0;
    margin-bottom: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 13.5pt;

    #container-body {
      padding-bottom: 0;
    }
  }

  .text-small {
    font-size: 13pt;
  }

  .vertical-centered {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  h1 {
    &.pir-title {
      font-size: 18.5pt;
      font-weight: bold;
    }

    &.pil-title {
      font-size: 20pt;
      font-weight: bold;
    }

    font-weight: bold;
    margin-top: 0;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 16.5pt;
    font-weight: bold;
    margin-bottom: 25px;

    &.pil-subtitle {
      font-size: 16.5pt;
      margin-bottom: 15px;
    }

    &.pil-table-title {
      font-size: 14pt;
    }

    &.pil-product {
      font-size: 13.5pt;
    }

  }

  h3 {
    font-size: 14pt;
    font-weight: bold;
    margin-bottom: 2px;
  }
  h4 {
    font-size: 100%;
    font-weight: bold;
  }

  p {
    font-size: 13.5pt;
    line-height: 1.3em;
  }

  .sf-toolbar {
    display: none !important;
  }

  .avoid-page-break {
    page-break-inside: avoid;
  }

  .page-break {
    page-break-after: always;
  }

  .big-strong {
    font-size: 15.5pt;
  }

  .full-row {
    float: left;
    width: 100%;
    margin: 0 !important;
  }

  .half-row, .col-sm-6 {
    float: left;
    width: 50%;
  }

  .one-fourth-row, .col-sm-3 {
    float: left;
    width: 100%/12*3;
  }

  .col-sm-4 {
    float: left;
    width: 100%/12*4;
  }

  .three-fourths-row {
    float: left;
    width: 75%;
  }

  .one-third-row {
    float: left;
    width: 33.3333%;
  }

  .two-thirds-row {
    float: left;
    width: 66.6666%;
  }

  .mb20 {
    margin-bottom: 20px;
  }

  .hide-in-pdf {
    display: none;
  }

  .pr20 {
    padding-right: 20px;
  }
  .batch-release {
    .order-date-column {
      float: left;
      width: 20%;
    }

    .order-initiator-column {
      float: left;
      width: 35%;
    }

    .stock-column {
      float: left;
      width: 10%;
    }
    .balance-offset {
      margin-left: 12%;
    }
    .deviation > div{
      float: left;
    }
    .pir-title {
      margin-bottom: -18px;
      font-size: 18.5pt;
    }

    .pir-date {
      font-size: 120%;
    }
    .pir-signature {
      font-size: 12pt;
    }

    .repacked-product, .original-product {
      text-decoration: underline;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .po-report {
    .original-product,
    .final-product {
      margin-bottom: 10px;
      border: 1px solid black;

      .product-type-title {
        .additional-info {
          font-size: 10px;
        }

        border-bottom: 1px solid black;
        font-size: 20px;
        text-align: center;
        padding-bottom: 15px;
      }

      .country {
        border-bottom: 1px solid black;
        padding: 10px 0;

        &.original-country {
          border-top: 1px solid black;
        }
      }
    }
    .original-product {
      .product-type-title {
        border-bottom: none;
      }
    }
  }

  .ro-report {
    p, td, th, b {
      font-size: 14px;
    }
    h4 {
      font-size: 16px;
    }
  }

  .alert-info td{
    background-color: #d9edf7 !important;
    border-color: #bce8f1 !important;
    color: #31708f !important;
  }
  .alert-danger td{
    background-color: #f2dede !important;
    border-color: #ebccd1 !important;
    color: #a94442 !important;
  }

  .hide-text {
    display: none;
  }

  .leaflet {
    .no-bold {
      font-weight: 300;
    }
  }

  .quality-certificate {
    page-break-before: always;
    .table {
      td {
        border: 1px solid black !important;
        padding-top: 0;
        padding-bottom: 0;

        .check-box {
          border: 1px solid black;
          width: 10px;
          height: 10px;
          float: left;
          margin: 7px 3px;
        }
        .confirmed {
          float: left;
        }
      }
    }
  }

  .pdf-header {
    font-size: 12pt;
    line-height: 1em;
    margin-bottom: 10px;
    p {
      line-height: 1em;
    }
  }

  .col-sm-offset-1 {
    margin-left: 100%/12;
  }

  .col-sm-offset-2-pdf {
    margin-left: 100%/12*2;
  }

  .col-sm-offset-3 {
    margin-left: 100%/12*3;
  }

  .col-sm-offset-4 {
    margin-left: 100%/12*4;
  }

  .col-sm-offset-5 {
    margin-left: 100%/12*5;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 100%/12*7;
  }

  .col-sm-1 {
    float: left;
    width: 100%/12;
  }

  .col-sm-2 {
    float: left;
    width: 100%/12*2;
  }

  .col-sm-10 {
    float: left;
    width: 100%/12*10;
  }

  .col-sm-12 {
    float: left;
    width: 100%;
  }

  .hide-desktop {
    display: block;
  }

  .no-margin {
    margin: 0;
  }

  .order-sold,
  .order-balance {
    float: left;
    width: 15%;
  }
}
