.page-content {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
}

.w-20 {
  width: 20%;
}
.w-40 {
  width: 40%;
}

@media (min-width: 992px) {
  .container-fluid {
    padding-left: 60px;
    padding-right: 60px;
  }

  .page-content {
    margin-top: -4px;
    padding-top: 80px;
    position: relative;
  }
}

@media (min-width: 1200px) {
  .page-content {
    padding-top: 55px;
  }
}

.fluid-layout-wrapper {

  // ---- BREADCRUMBS ----

  .breadcrumbs {
    overflow: hidden;
    margin-bottom: 30px;
    padding-right: 6px;

    h1 {
      margin: 0 20px 0 0;
      float: left;
      font-size: 22px;
      font-weight: 500;
      line-height: 1.4;
      color: #2a3239;
      font-family: Oswald, sans-serif;
      text-transform: uppercase;
    }

    .breadcrumb {
      margin: 0;
      padding: 0;
      text-align: right;
      background: inherit;

      > li {
        font-size: 13px;

        &.active {
          color: #009dc7;
          text-transform: uppercase;
        }

        > a {
          color: #6c7b88;
          text-transform: uppercase;
        }

        & + li:before {
          top: 2px;
          color: #dfe4e6;
          font-size: 20px;
          content: "\2022";
          position: relative;
          padding: 0 8px 0 5px;
        }
      }
    }
  }
}
